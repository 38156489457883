import { applyMiddleware } from 'redux';
import { legacy_createStore as createStore } from 'redux';
import { thunk } from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from './reducer';
import { composeWithDevTools } from '@redux-devtools/extension';
import createWebStorage from 'redux-persist/es/storage/createWebStorage';

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['currentQuiz'],
  timeout: 1000,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWare = applyMiddleware(thunk);

export default () => {
  let store = createStore(
    persistedReducer, 
    process.env.NODE_ENV === 'development' 
      ? composeWithDevTools(middleWare)
      : middleWare
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
